import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import InstagramLink from "../components/InstagramLink";

class Home extends React.Component {
  render() {
    return (



      <Grid container spacing={{ xs: 4, md: 8, lg: 16, xl: 32 }}>
        <Grid
          item
          xs={12}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <br></br>
          <br></br>
          I'm sorry.
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          I'm not sorry.
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
        </Grid>
      </Grid>
    );
  }
}
export default Home;
