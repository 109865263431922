import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import React, { useState } from "react";

class App extends React.Component {
  render() {
    return (
        <BrowserRouter>
        <React.StrictMode>

          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
          </React.StrictMode>
        </BrowserRouter>
    );
  }
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

