import * as React from "react";
import { Outlet, Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { PaletteMode } from "@mui/material";
import { grey, green } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import BasicMenu from "./components/BasicMenu";
import Footer from "./components/Footer";
// import App from "./App";

import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";
import "@fontsource/plus-jakarta-sans";

const ColorModeContext = React.createContext({ toggleColorMode: () => {
} });

function MyLayout() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <React.Fragment>
      <CssBaseline />

      <Grid container spacing={{ xs: 4, md: 8, lg: 16, xl: 32 }}>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          {/* <BasicMenu /> */}
        </Grid>
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={10}
          sx={{ alignItems: "center", display: "flex", justifyContent: "flex-end" }}
        >
          <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
            {theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Grid>
      </Grid>

      <Grid container spacing={{ xs: 4, md: 8, lg: 16, xl: 32 }}>
        <Grid
          item
          xs={12}
          sx={{
            fontFamily: "Plus Jakarta Sans",
            fontSize: 120,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          ox+xo
        </Grid>
      </Grid>

      <Grid container spacing={{ xs: 4, md: 8, lg: 16, xl: 32 }}>
        <Grid item xs={12} sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
          <Outlet />
          {/* <App></App> */}
        </Grid>
      </Grid>

      <Grid container spacing={{ xs: 4, md: 8, lg: 16, xl: 32 }}>
        <Grid item xs={12} sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
          <Footer></Footer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}


  export default function ToggleColorMode() {
    const [mode, setMode] = React.useState<"dark" | "light">("dark");
  
    const colorMode = React.useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === "dark" ? "light" : "dark"));
        },
      }),
      []
    );
  
    const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
      mode,
      ...(mode === "light"
        ? {
            type: "light",

            background: {
              default: "#f4f1f4",
              paper: "#f4f1f4",
            },

            shape: {
              boxBorderRadius: 0,
            },
            text: {
              primary: grey[800],
              secondary: grey[800],
            },
            action: {
              hover: grey[400],
              hoverOpacity: 1,
            },
            primary: {
              main: grey[800],
            },
          }
        : {
            type: "dark",

            background: {
              default: grey[900],
              paper: grey[900],
            },

            shape: {
              boxBorderRadius: 0,
            },
            text: {
              primary: "#ddd4dd",
              secondary: "#ddd4dd",
            },
            action: {
              hover: grey[800],
              hoverOpacity: 1,
            },
            primary: {
              main: "#ddd4dd",
            },
          }),
    },
  });

  const theme = createTheme(getDesignTokens(mode));

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <MyLayout />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
