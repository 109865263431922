import React from "react";
// import { Link as Link1 } from "@mui/material";
import Link from "@mui/material/Link";
// import { Link } from 'react-router-dom';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tab";
import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Instagram from "@mui/icons-material/Instagram";

export default function InstagramLink() {
  return (
    <Link href="http://www.instagram.com/oxtxo_art">
      <Instagram> </Instagram>
    </Link>
  );
}
