import React, { useState } from "react";


interface IProps {
  name: string;
  age: number;
  country: string;
  children?: React.ReactNode; // 👈️ for demo purposes
}

interface IState {
  counter: number;
}

class Blogs extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      counter: 0,
    };
    this.myFunction = this.myFunction.bind(this);
  }
  myFunction(e: React.ChangeEvent<any>) {
    alert("The value of counter is " + this.state.counter);
    this.setState({ counter: this.state.counter + 1 });
  }
  // function App() {
  render() {
    return (
        <h1>Blogs  Foo!!!</h1>
        );
  }
}
export default Blogs;
