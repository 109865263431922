import React, { useState } from "react";
import InstagramLink from "./InstagramLink";

class Footer extends React.Component {
  render() {
    return (

      <InstagramLink></InstagramLink>        );
  }
}
export default Footer;
